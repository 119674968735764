<template>
  <transition name="fade">
    <div v-if="show" class="modal-container">
      <div class="w-full max-w-xl">
        <div class="bg-white rounded overflow-hidden">
          <!-- Heading -->
          <div class="p-4 md:p-6 font-bold uppercase">
            Legg til avdeling til et fakultet
          </div>

          <!-- Main Content -->
          <div class="px-4 pb-4 md:px-6">
            <!-- Connection Selector -->
            <div class="flex flex-row justify-between">
              <!-- Building selector -->
              <el-select v-model="selectedFaculty" placeholder="Select faculty">
                <el-option
                    v-for="item in optionalFaculties"
                    :key="item.facultyId"
                    :label="item.facultyName"
                    :value="item.facultyId">
                </el-option>
              </el-select>

              <!-- illustration with arrows -->
              <div class="flex flex-row self-center">
                <i class="el-icon-back"></i>
                <i class="el-icon-right"></i>
              </div>

              <!-- Department selector -->
              <el-select v-model="selectedDepartment" placeholder="Select department">
                <el-option
                    v-for="item in departments"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>

          <!-- Footer -->
          <div class="p-4 md:p-6 bg-gray-100">
            <div class="flex justify-end">
              <ui-link type="text" class="ml-4" @click="handleCancel">
                {{cancelText}}
              </ui-link>
              <ui-link type="success" @click="handleConfirm">
                {{confirmText}}
              </ui-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>


<script>
import UiLink from '@/components/ui/UiLink';
import {getBuildingDepartments} from "@/app/buildings/api";

export default {
  name: "AddFacultyDepartmentModal",

  components: {
    UiLink,
  },

  props: {
    faculty: {
      type: Object,
    },
    optionalFaculties: {
      type: Array,
      Required: true
    },
    show: {
      type: Boolean,
      required: true
    },
    confirmText: {
      type: String,
      default: function() {
        return this.$t('actions.confirm');
      }
    },
    cancelText: {
      type: String,
      default: function() {
        return this.$t('actions.cancel');
      }
    }
  },

  data() {
    return {
      // Data
      departments: [],
      buildingId: null,

      // User input
      selectedFaculty: null,
      selectedDepartment: null
    }
  },

  watch: {
    selectedFaculty: async function() {
      if (this.buildingId) {
        this.departments = await getBuildingDepartments(this.buildingId);
      } else {
        const buildingId = this.optionalFaculties.find(f => f.facultyId === this.selectedFaculty).buildingId;
        this.departments = await getBuildingDepartments(buildingId);
      }
    },

    faculty: function() {
      if (this.faculty) {
        this.selectedFaculty = this.faculty.facultyId;
      }
    }
  },

  methods: {
    handleConfirm() {
      this.$emit('confirm', {
        facultyId: this.selectedFaculty,
        departmentId: this.selectedDepartment,
      });
      this.selectedBuilding = null;
    },

    handleCancel() {
      this.$emit('cancel');
      this.selectedBuilding = null;
    },
  },

  async mounted() {
    if (this.faculty) {
      this.selectedFaculty = this.faculty.facultyId;
      this.buildingId = this.faculty.buildingId;
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-container {
  @apply fixed inset-0 flex items-center justify-center px-3 py-6;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;

  @screen sm {
    @apply px-4 py-8;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
